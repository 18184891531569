import Alpine from 'alpinejs';

import '../css/style.css';

window.Alpine = Alpine;
Alpine.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}
